import { baseURL } from '../../../../utils/apihost'
import { fetchGet } from '../../../../utils/format'

export default {
  async getMenu(token) {
    let responseJSON = await fetchGet(baseURL + `/menu`, token)
    return responseJSON
  },
  async getAction(id, token) {
    let responseJSON = await fetchGet(baseURL + `/actionlist/${id}`, token)
    return responseJSON
  },
}
